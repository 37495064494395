import React, {FC} from "react";
import styled from "styled-components";
import {pxToRem} from "../utils/pxToRem";

interface Props {
  label: string;
  id: string;
  handleClick: (id: string) => void;
  active: boolean;
}
export const Chip: FC<Props> = ({ label, id, handleClick, active }) => {
  return (
    <StyledChip onClick={(e) => handleClick(id)} active={active}>
      {label}
    </StyledChip>
  );
};

const StyledChip = styled.button<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? "var(--color-primary)" : "#f6f6f6"};
  color: ${(props) => (props.active ? "white" : "var(--color-primary)")};
  border: none;
  padding: ${pxToRem(8)} ${pxToRem(12)};
  margin: ${pxToRem(12)} ${pxToRem(12)} 0 0;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  &:hover {
    background-color: var(--color-primary);
    color: white;
  }
`;
